import {
    getDimensionsForSelectedVariant,
    GetDimensionsForSelectedVariantInput,
    getDimensionAvailabilitiesForProductsAction,
    ProductsDimensionAvailabilitiesInput
} from '@msdyn365-commerce-modules/retail-actions';
import { ProductsDataActions, SimpleProduct } from '@msdyn365-commerce/retail-proxy';
import { IActionContext, generateImageUrl as generateImageUrlFromSdk } from '@msdyn365-commerce/core';

export async function getProductsByMasterProductId(productId: number, channelId: number, actionContext: IActionContext): Promise<SimpleProduct[]>  {
    const dimensionsForSelectedVariant = await getDimensionsForSelectedVariant(new GetDimensionsForSelectedVariantInput(productId, channelId, []), actionContext)

    const productsDimensionAvailabilitiesInputs = dimensionsForSelectedVariant.map(dimensionForSelectedVariant => {
        return new ProductsDimensionAvailabilitiesInput(productId, dimensionForSelectedVariant.DimensionTypeValue, actionContext.requestContext);
    });

    const dimensionAvailabilitiesForProducts = await getDimensionAvailabilitiesForProductsAction(productsDimensionAvailabilitiesInputs, actionContext);

    let productIdsAll: number[] = [];
    dimensionAvailabilitiesForProducts?.forEach(productsDimensionAvailabilitie => {
        productsDimensionAvailabilitie?.forEach( productsAvailabilitie => {
            if(productsAvailabilitie?.ProductIds) {
                productIdsAll = [...productsAvailabilitie.ProductIds , ...productIdsAll]
            }
        } )
    });
    
    const getProductDetailsPromises = productIdsAll.map(productId => getProductDetailsWithImageUrl(productId, channelId, actionContext));

    const allProducts = await Promise.all(getProductDetailsPromises);

    return allProducts.filter(product => product !== null).map(product => product as SimpleProduct);
}

export async function getProductDetailsWithImageUrl(productId: number, channelId: number, context: IActionContext): Promise<SimpleProduct | null> {
    let product: SimpleProduct | null = null;
    try {
        product = await ProductsDataActions.getByIdAsync({ callerContext: context }, productId, channelId);
        const newImageUrl = generateImageUrlFromSdk(context.requestContext.apiSettings, product.PrimaryImageUrl);
        if (newImageUrl) {
            product.PrimaryImageUrl = newImageUrl;
        }
    }
    catch (ex) {
        console.log(ex);
    }
    return product;
}
