import { IActionContext } from '@msdyn365-commerce/core';
import { ProductSearchResult, ProductsDataActions } from '@msdyn365-commerce/retail-proxy';
import { getProductAvailabilitiesForSearchResults } from '../get-product-availabilities-for-selected-variant.action';
import { ArrayExtensions, DeliveryMode} from '@msdyn365-commerce-modules/retail-actions'
/**
 * The full product search result with count interface.
 */
export interface IFullProductsSearchResultsWithCount {
    products: ProductSearchResult[];
    count: number;
    channelInventoryConfigurationId?: number;
    inventoryAwareSortableAttributeId?: number;
}

export const sortByOutOfStock = async (productSearchResults: ProductSearchResult[], context: IActionContext): Promise<ProductSearchResult[]> => {

    await calculateAvailabilityBasedOnProductAvailability(productSearchResults, context);
    await calculateAvailabilityBasedOnProductDeliveryOptions(productSearchResults, context);

    let filteredResult = applyOosDisplaySetting(productSearchResults, context);

    return filteredResult;
}

const _isProductInStock = (productSearchResult: ProductSearchResult): boolean => {

    if (productSearchResult) {
        let pickUpAvail = productSearchResult?.ExtensionProperties?.find(x => x.Key == "TRUS_PICKUP_AVAILABILITY");
        let shipmentAvail = productSearchResult?.ExtensionProperties?.find(x => x.Key == "TRUS_SHIPMENT_AVAILABILITY");
        return pickUpAvail?.Value?.BooleanValue === true || shipmentAvail?.Value?.BooleanValue === true;
    }

    return false;
};

const calculateAvailabilityBasedOnProductAvailability = async (productSearchResults: ProductSearchResult[], context: IActionContext) =>  {
    const allProductAvailabilities = await getProductAvailabilitiesForSearchResults(productSearchResults, context);
    for (let productSearchResult of productSearchResults) {

        let pickUpAvail = false;
        let shipmentAvail = false;

        let productAvailabilities = allProductAvailabilities.filter(pa => pa.masterProductId == productSearchResult.RecordId);

        if (productAvailabilities.length) {
            if (!productSearchResult.IsMasterProduct) {
                const shipmentProductAvailability = ArrayExtensions.firstOrDefault(productAvailabilities.filter(x => x.deliveryType === DeliveryMode.shipping));
                if (shipmentProductAvailability && shipmentProductAvailability?.ProductAvailableQuantity.AvailableQuantity && shipmentProductAvailability?.ProductAvailableQuantity.AvailableQuantity > 0) {
                    shipmentAvail = true;
                }
                const pickUpProductAvailability = ArrayExtensions.firstOrDefault(productAvailabilities.filter(x => x.deliveryType === DeliveryMode.pickup));
                if (pickUpProductAvailability && pickUpProductAvailability?.ProductAvailableQuantity.AvailableQuantity && pickUpProductAvailability?.ProductAvailableQuantity.AvailableQuantity > 0) {
                    pickUpAvail = true;
                }
            }
            else {
                const shipmentProductAvailability = productAvailabilities
                    .filter(x => x.deliveryType === DeliveryMode.shipping)
                    .filter(x => x && x.ProductAvailableQuantity?.AvailableQuantity && x.ProductAvailableQuantity.AvailableQuantity > 0);
                if (ArrayExtensions.hasElements(shipmentProductAvailability)) {
                    shipmentAvail = true;
                }

                const pickUpProductAvailability = productAvailabilities
                    .filter(x => x.deliveryType === DeliveryMode.pickup)
                    .filter(x => x && x.ProductAvailableQuantity?.AvailableQuantity && x.ProductAvailableQuantity.AvailableQuantity > 0);
                if (ArrayExtensions.hasElements(pickUpProductAvailability)) {
                    pickUpAvail = true;
                }
            }
        }
        else {
            //if error occured mark product as available
            pickUpAvail = true;
            shipmentAvail = true;
        }

        productSearchResult.ExtensionProperties = productSearchResult.ExtensionProperties || [];
        let extProp = productSearchResult.ExtensionProperties.find(x => x.Key == "TRUS_PICKUP_AVAILABILITY");
        if (!extProp) {
            extProp = { Key: "TRUS_PICKUP_AVAILABILITY", Value: { BooleanValue: pickUpAvail } };
            productSearchResult.ExtensionProperties.push(extProp);
        }
        else {
            extProp.Value = { BooleanValue: pickUpAvail };
        }

        extProp = productSearchResult.ExtensionProperties.find(x => x.Key == "TRUS_SHIPMENT_AVAILABILITY");
        if (!extProp) {
            extProp = { Key: "TRUS_SHIPMENT_AVAILABILITY", Value: { BooleanValue: shipmentAvail } };
            productSearchResult.ExtensionProperties.push(extProp);
        }
        else {
            extProp.Value = { BooleanValue: shipmentAvail };
        }
    }
}

const calculateAvailabilityBasedOnProductDeliveryOptions = async (productSearchResults: ProductSearchResult[], context: IActionContext) => {
    const emailDeliveryModeCode = context.requestContext.channel?.EmailDeliveryModeCode;
    let oosProducts = productSearchResults?.filter((searchResult) => !_isProductInStock(searchResult));
    if (oosProducts && ArrayExtensions.hasElements(oosProducts)) {
        const oosProductIds = oosProducts.map(x => x.RecordId).filter((value, index, self) => self.indexOf(value) === index);
        const productDeliveryOptions = await ProductsDataActions.getDeliveryOptionsAsync(
            {
                callerContext: context,
                queryResultSettings: { Paging: { Top: 1000 } }
            },
            oosProductIds,
            {},
            null
        );

        for (let productDeliveryOption of productDeliveryOptions) {
            const isEmailDelivery = ArrayExtensions.hasElements(productDeliveryOption?.DeliveryOptions) && productDeliveryOption!.DeliveryOptions[0].Code === emailDeliveryModeCode;
            if (isEmailDelivery) {
                let products = oosProducts?.filter((p) => p.RecordId === productDeliveryOption.ProductId);
                products.forEach(p => {
                    let extProp = p?.ExtensionProperties?.find(x => x.Key == "TRUS_SHIPMENT_AVAILABILITY");
                    if (!extProp) {
                        extProp = { Key: "TRUS_SHIPMENT_AVAILABILITY", Value: { BooleanValue: true } };
                        p!.ExtensionProperties!.push(extProp);
                    }
                    else {
                        extProp.Value = { BooleanValue: true };
                    }
                });
            }
        }
    }
}

function applyOosDisplaySetting(productSearchResults: ProductSearchResult[], context: IActionContext) {
    let filteredResult = productSearchResults;
    if (context.requestContext.app.config?.searchPageOOSBehaviour === "hideOOS") {
        filteredResult = productSearchResults?.filter((searchResult) => _isProductInStock(searchResult));
    }
    else if (context.requestContext.app.config?.searchPageOOSBehaviour === "placeOOSInTheEnd") {
        filteredResult = productSearchResults?.sort((a, b) => {
            const availabilityA = _isProductInStock(a) ? 1 : 0;
            const availabilityB = _isProductInStock(b) ? 1 : 0;
            return availabilityB - availabilityA;
        });
    }
    return filteredResult;
}
