import { createDataActionHook, IActionInput } from '@msdyn365-commerce/core';
import { ItemAvailability } from '@msdyn365-commerce/retail-proxy';
import { ArrayExtensions, IFullOrgUnitAvailability } from '@msdyn365-commerce-modules/retail-actions';

const preHook = async (inputs: IActionInput | IActionInput[]) => {};

const postHook = async (input: IActionInput | IActionInput[], results: IFullOrgUnitAvailability[], context: any): Promise<void | IFullOrgUnitAvailability[]> => {

    const filteredResult = results?.sort((a,b) => {
        let availabilityA = _isProductInStock(a?.OrgUnitAvailability?.ItemAvailabilities) ? 1 : 0;
        let availabilityB = _isProductInStock(b?.OrgUnitAvailability?.ItemAvailabilities) ? 1 : 0;
        return availabilityB - availabilityA;
    });

    return filteredResult;
};

const _isProductInStock = (itemAvailabilities: ItemAvailability[] | undefined): boolean => {

    if (itemAvailabilities && ArrayExtensions.hasElements(itemAvailabilities)) {
        return itemAvailabilities[0].AvailableQuantity ? itemAvailabilities[0].AvailableQuantity > 0 : false;
    }

    return false;
};

createDataActionHook<any>({
    actionId: '@msdyn365-commerce-modules/retail-actions/get-full-available-inventory-nearby',
    postHook: postHook,
    preHook: preHook
});